import * as React from "react"
import { graphql, Link } from "gatsby"
import FrontmatterPageContext from "~context/FakePageContext"
// import { LinkDataURL } from "~components/data_url"
import { Box, Text, Stack } from "@fastly/consistently"
import { main } from "~vanilla/layout-default.css"
import { mainArea } from "~vanilla/layout-default-full.css"

import LayoutCore from "~templates/layout-core"

import NavAccountData from "~data/nav_account.yml"
import NavComputeData from "~data/nav_compute.yml"
import NavFullsiteData from "~data/nav_full_site_delivery.yml"
import NavGettingstartedData from "~data/nav_getting_started.yml"
import NavIntegrationsData from "~data/nav_integrations.yml"
import NavSecurityData from "~data/nav_security.yml"

import { FavHead } from "~components/Head/FavHead"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { CodeBlock } from "~components/CodeBlock"
import Alert from "~components/alert"
import T from "~components/translator"
import mailto from "~components/mailto"
import DRY from "~components/dry"
import Raw from "~components/raw"
import Frontmatter from "~components/frontmatter"
import { LimitedAvailBadge } from "~components/avail-badges"
import FloatedImage from "~components/floated-image"
import { PageHeaderTable, DinkusBar } from "~components/AIOHelper/AIOHelper"

import * as Includes from "~components/includes"
// these came from the /index.md page
let frontmatter = {
  // layout: "default_full",
  section: "guides",
  section_safe: "guides",
  title: "Fastly Guides Archive | Fastly Documentation",
  lang: "en",
}

// for MDXProvider later
let mdx_components = {
  T,
  Alert,
  mailto,
  ...Includes,
  DRY,
  Raw,
  Box,
  CodeBlock,
  pre: CodeBlock,
  FloatedImage,
  Frontmatter,
  LimitedAvailBadge,
}

const GuidesEnAioPage = ({ data, location }) => {
  let APrimaryDatas = {}
  NavAccountData.forEach((element) => {
    const { crumb: pkey, "secondary-nav": snav = [] } = element

    let thisPrimary = data.aprimaries.nodes.filter((nod) => nod.frontmatter.cat_id === pkey)[0]
    let p_frontmatter = thisPrimary.frontmatter

    // its ugly, but we can hardcode this since tihs is only used on this en/guides page
    p_frontmatter["section_url"] = "/en/guides/"

    let thisPrimaryGuides = data.account_in_primarys.group.find((ggip) => ggip.fieldValue === pkey)

    let myOrphans = data.aprimary_orphans.group.find((g) => g.fieldValue === pkey)

    APrimaryDatas[pkey] = {
      pkey: pkey,
      pfrontmatter: p_frontmatter,
      thisPrimaryGuidesNodes: thisPrimaryGuides?.nodes,
      myOrphans: myOrphans?.nodes,
    }
  })

  let CPrimaryDatas = {}
  NavComputeData.forEach((element) => {
    const { crumb: pkey, "secondary-nav": snav = [] } = element

    let thisPrimary = data.cprimaries.nodes.filter((nod) => nod.frontmatter.cat_id === pkey)[0]
    let p_frontmatter = thisPrimary.frontmatter

    // its ugly, but we can hardcode this since tihs is only used on this en/guides page
    p_frontmatter["section_url"] = "/en/guides/"

    let thisPrimaryGuides = data.compute_in_primarys.group.find((ggip) => ggip.fieldValue === pkey)

    let myOrphans = data.cprimary_orphans.group.find((g) => g.fieldValue === pkey)

    CPrimaryDatas[pkey] = {
      pkey: pkey,
      pfrontmatter: p_frontmatter,
      thisPrimaryGuidesNodes: thisPrimaryGuides?.nodes,
      myOrphans: myOrphans?.nodes,
    }
  })

  let DPrimaryDatas = {}
  NavFullsiteData.forEach((element) => {
    const { crumb: pkey, "secondary-nav": snav = [] } = element

    let thisPrimary = data.dprimaries.nodes.filter((nod) => nod.frontmatter.cat_id === pkey)[0]
    let p_frontmatter = thisPrimary.frontmatter

    // its ugly, but we can hardcode this since tihs is only used on this en/guides page
    p_frontmatter["section_url"] = "/en/guides/"

    let thisPrimaryGuides = data.fullsite_in_primarys.group.find((ggip) => ggip.fieldValue === pkey)

    let myOrphans = data.dprimary_orphans.group.find((g) => g.fieldValue === pkey)

    DPrimaryDatas[pkey] = {
      pkey: pkey,
      pfrontmatter: p_frontmatter,
      thisPrimaryGuidesNodes: thisPrimaryGuides?.nodes,
      myOrphans: myOrphans?.nodes,
    }
  })

  let GPrimaryDatas = {}
  NavGettingstartedData.forEach((element) => {
    const { crumb: pkey, "secondary-nav": snav = [] } = element

    let thisPrimary = data.gprimaries.nodes.filter((nod) => nod.frontmatter.cat_id === pkey)[0]
    let p_frontmatter = thisPrimary.frontmatter

    // its ugly, but we can hardcode this since tihs is only used on this en/guides page
    p_frontmatter["section_url"] = "/en/guides/"

    let thisPrimaryGuides = data.gettingstarted_in_primarys.group.find((ggip) => ggip.fieldValue === pkey)

    let myOrphans = data.gprimary_orphans.group.find((g) => g.fieldValue === pkey)

    GPrimaryDatas[pkey] = {
      pkey: pkey,
      pfrontmatter: p_frontmatter,
      thisPrimaryGuidesNodes: thisPrimaryGuides?.nodes,
      myOrphans: myOrphans?.nodes,
    }
  })

  let IPrimaryDatas = {}
  NavIntegrationsData.forEach((element) => {
    const { crumb: pkey, "secondary-nav": snav = [] } = element

    let thisPrimary = data.iprimaries.nodes.filter((nod) => nod.frontmatter.cat_id === pkey)[0]
    let p_frontmatter = thisPrimary.frontmatter

    // its ugly, but we can hardcode this since tihs is only used on this en/guides page
    p_frontmatter["section_url"] = "/en/guides/"

    let thisPrimaryGuides = data.integrations_in_primarys.group.find((ggip) => ggip.fieldValue === pkey)

    let myOrphans = data.iprimary_orphans.group.find((g) => g.fieldValue === pkey)

    IPrimaryDatas[pkey] = {
      pkey: pkey,
      pfrontmatter: p_frontmatter,
      thisPrimaryGuidesNodes: thisPrimaryGuides?.nodes,
      myOrphans: myOrphans?.nodes,
    }
  })

  let SPrimaryDatas = {}
  NavSecurityData.forEach((element) => {
    const { crumb: pkey, "secondary-nav": snav = [] } = element

    let thisPrimary = data.sprimaries.nodes.filter((nod) => nod.frontmatter.cat_id === pkey)[0]
    let p_frontmatter = thisPrimary.frontmatter

    // its ugly, but we can hardcode this since tihs is only used on this en/guides page
    p_frontmatter["section_url"] = "/en/guides/"

    let thisPrimaryGuides = data.security_in_primarys.group.find((ggip) => ggip.fieldValue === pkey)

    let myOrphans = data.sprimary_orphans.group.find((g) => g.fieldValue === pkey)

    SPrimaryDatas[pkey] = {
      pkey: pkey,
      pfrontmatter: p_frontmatter,
      thisPrimaryGuidesNodes: thisPrimaryGuides?.nodes,
      myOrphans: myOrphans?.nodes,
    }
  })

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutCore>
          <div className={`${mainArea} grid-main layout-guide_article`}>
            <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
              <div className="content-content">
                <div>
                  <Stack direction={["horizontal"]} gap={["md"]}>
                    <>Jump to:</>
                    <a href="#_account">Account info</a>
                    <a href="#_compute">Compute</a>
                    <a href="#_fullsite">Full Site Delivery</a>
                    <a href="#_gettingstarted">Getting started</a>
                    <a href="#_integrations">Integrations</a>
                    <a href="#_security">Security</a>
                  </Stack>
                </div>

                <h1 id={`_account`}><a href="/en/guides/account-info">Account info guides</a></h1>
                {NavAccountData.map((ngd) => {
                  let pdata = APrimaryDatas[ngd.crumb]
                  if (ngd?.unlisted) {
                    return <></>
                  }
                  let pURL = `${pdata.pfrontmatter.section_url}${pdata.pkey}`

                  return (
                    <section>
                      <Box bg={`var(--color-blue-20)`} border="5px outset #999999" padding="1em" mb="0.5em">
                        <Text variant="display2xl">
                          Category: <a href={pURL}>{pdata.pfrontmatter.section_name}</a>
                        </Text>
                        <p style={{ marginBottom: 0 }}>{pdata.pfrontmatter.description}</p>
                      </Box>

                      {pdata.myOrphans?.map((og) => {
                        return (
                          <>
                            <PageHeaderTable guide={og} section_url_prefix={pdata.pfrontmatter.section_url} />

                            <hr />
                            <FrontmatterPageContext.Provider value={og.frontmatter}>
                              <MDXProvider components={mdx_components}>
                                <MDXRenderer>{og.body}</MDXRenderer>
                              </MDXProvider>
                            </FrontmatterPageContext.Provider>

                            <DinkusBar />
                          </>
                        )
                      })}
                    </section>
                  )
                })}

                <h1 id={`_compute`}><a href="/en/guides/compute">Compute guides</a></h1>
                {NavComputeData.map((ngd) => {
                  let pdata = CPrimaryDatas[ngd.crumb]
                  if (ngd?.unlisted) {
                    return <></>
                  }
                  let pURL = `${pdata.pfrontmatter.section_url}${pdata.pkey}`

                  return (
                    <section>
                      <Box bg={`var(--color-blue-20)`} border="5px outset #999999" padding="1em" mb="0.5em">
                        <Text variant="display2xl">
                          Category: <a href={pURL}>{pdata.pfrontmatter.section_name}</a>
                        </Text>
                        <p style={{ marginBottom: 0 }}>{pdata.pfrontmatter.description}</p>
                      </Box>

                      {pdata.myOrphans?.map((og) => {
                        return (
                          <>
                            <PageHeaderTable guide={og} section_url_prefix={pdata.pfrontmatter.section_url} />

                            <hr />
                            <FrontmatterPageContext.Provider value={og.frontmatter}>
                              <MDXProvider components={mdx_components}>
                                <MDXRenderer>{og.body}</MDXRenderer>
                              </MDXProvider>
                            </FrontmatterPageContext.Provider>

                            <DinkusBar />
                          </>
                        )
                      })}
                    </section>
                  )
                })}

                <h1 id={`_fullsite`}><a href="/en/guides/full-site-delivery">Full Site Delivery guides</a></h1>
                {NavFullsiteData.map((ngd) => {
                  let pdata = DPrimaryDatas[ngd.crumb]
                  if (ngd?.unlisted) {
                    return <></>
                  }
                  let pURL = `${pdata.pfrontmatter.section_url}${pdata.pkey}`

                  return (
                    <section>
                      <Box bg={`var(--color-blue-20)`} border="5px outset #999999" padding="1em" mb="0.5em">
                        <Text variant="display2xl">
                          Category: <a href={pURL}>{pdata.pfrontmatter.section_name}</a>
                        </Text>
                        <p style={{ marginBottom: 0 }}>{pdata.pfrontmatter.description}</p>
                      </Box>

                      {pdata.myOrphans?.map((og) => {
                        return (
                          <>
                            <PageHeaderTable guide={og} section_url_prefix={pdata.pfrontmatter.section_url} />

                            <hr />
                            <FrontmatterPageContext.Provider value={og.frontmatter}>
                              <MDXProvider components={mdx_components}>
                                <MDXRenderer>{og.body}</MDXRenderer>
                              </MDXProvider>
                            </FrontmatterPageContext.Provider>

                            <DinkusBar />
                          </>
                        )
                      })}
                    </section>
                  )
                })}

                <h1 id={`_gettingstarted`}><a href="/en/guides/getting-started">Getting started guides</a></h1>
                {NavGettingstartedData.map((ngd) => {
                  let pdata = GPrimaryDatas[ngd.crumb]
                  if (ngd?.unlisted) {
                    return <></>
                  }
                  let pURL = `${pdata.pfrontmatter.section_url}${pdata.pkey}`

                  return (
                    <section>
                      <Box bg={`var(--color-blue-20)`} border="5px outset #999999" padding="1em" mb="0.5em">
                        <Text variant="display2xl">
                          Category: <a href={pURL}>{pdata.pfrontmatter.section_name}</a>
                        </Text>
                        <p style={{ marginBottom: 0 }}>{pdata.pfrontmatter.description}</p>
                      </Box>

                      {pdata.myOrphans?.map((og) => {
                        return (
                          <>
                            <PageHeaderTable guide={og} section_url_prefix={pdata.pfrontmatter.section_url} />

                            <hr />
                            <FrontmatterPageContext.Provider value={og.frontmatter}>
                              <MDXProvider components={mdx_components}>
                                <MDXRenderer>{og.body}</MDXRenderer>
                              </MDXProvider>
                            </FrontmatterPageContext.Provider>

                            <DinkusBar />
                          </>
                        )
                      })}
                    </section>
                  )
                })}

                <h1 id={`_integrations`}><a href="/en/guides/integrations">Integrations guides</a></h1>
                {NavIntegrationsData.map((ngd) => {
                  let pdata = IPrimaryDatas[ngd.crumb]
                  if (ngd?.unlisted) {
                    return <></>
                  }
                  let pURL = `${pdata.pfrontmatter.section_url}${pdata.pkey}`

                  return (
                    <section>
                      <Box bg={`var(--color-blue-20)`} border="5px outset #999999" padding="1em" mb="0.5em">
                        <Text variant="display2xl">
                          Category: <a href={pURL}>{pdata.pfrontmatter.section_name}</a>
                        </Text>
                        <p style={{ marginBottom: 0 }}>{pdata.pfrontmatter.description}</p>
                      </Box>

                      {pdata.myOrphans?.map((og) => {
                        return (
                          <>
                            <PageHeaderTable guide={og} section_url_prefix={pdata.pfrontmatter.section_url} />

                            <hr />
                            <FrontmatterPageContext.Provider value={og.frontmatter}>
                              <MDXProvider components={mdx_components}>
                                <MDXRenderer>{og.body}</MDXRenderer>
                              </MDXProvider>
                            </FrontmatterPageContext.Provider>

                            <DinkusBar />
                          </>
                        )
                      })}
                    </section>
                  )
                })}

                <h1 id={`_security`}><a href="/en/guides/security">Security guides</a></h1>
                {NavSecurityData.map((ngd) => {
                  let pdata = SPrimaryDatas[ngd.crumb]
                  if (ngd?.unlisted) {
                    return <></>
                  }
                  let pURL = `${pdata.pfrontmatter.section_url}${pdata.pkey}`

                  return (
                    <section>
                      <Box bg={`var(--color-blue-20)`} border="5px outset #999999" padding="1em" mb="0.5em">
                        <Text variant="display2xl">
                          Category: <a href={pURL}>{pdata.pfrontmatter.section_name}</a>
                        </Text>
                        <p style={{ marginBottom: 0 }}>{pdata.pfrontmatter.description}</p>
                      </Box>

                      {pdata.myOrphans?.map((og) => {
                        return (
                          <>
                            <PageHeaderTable guide={og} section_url_prefix={pdata.pfrontmatter.section_url} />

                            <hr />
                            <FrontmatterPageContext.Provider value={og.frontmatter}>
                              <MDXProvider components={mdx_components}>
                                <MDXRenderer>{og.body}</MDXRenderer>
                              </MDXProvider>
                            </FrontmatterPageContext.Provider>

                            <DinkusBar />
                          </>
                        )
                      })}
                    </section>
                  )
                })}

              </div>
            </main>
          </div>
        </LayoutCore>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default GuidesEnAioPage

export const Head = (props) => {
  return (
    <>
      <title>{frontmatter.title}</title>
      <meta name="robots" content="noindex"></meta>
      <FavHead />
    </>
  )
}

export const sitemapQuery = graphql`
  query {
    account_in_primarys: allMdx(
      filter: { slug: { regex: "/^_en_account//" }, frontmatter: { unlisted: { ne: true }, orphan: { ne: true } } }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        totalCount
        nodes {
          slug
          frontmatter {
            primary_nav
            secondary_nav
            header
            unlisted
            last_updated(formatString: "YYYY-MM-DD")
          }
          body
        }
      }
    }
    aprimaries: allMdx(filter: { fileAbsolutePath: { regex: "/_en_account_primary/" } }) {
      nodes {
        fileAbsolutePath
        frontmatter {
          cat_id
          section_name
          svg
          description
        }
      }
    }
    aprimary_orphans: allMdx(
      filter: {
        frontmatter: {
          # orphan: { eq: true },
          unlisted: { ne: true }
        }
        fileAbsolutePath: { regex: "/_en_account//" }
      }
      sort: { fields: fileAbsolutePath }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        nodes {
          fileAbsolutePath
          slug
          frontmatter {
            primary_nav
            # secondary_nav
            header
            unlisted
            # orphan
          }
          body
        }
      }
    }

    compute_in_primarys: allMdx(
      filter: { slug: { regex: "/^_en_compute//" }, frontmatter: { unlisted: { ne: true }, orphan: { ne: true } } }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        totalCount
        nodes {
          slug
          frontmatter {
            primary_nav
            secondary_nav
            header
            unlisted
            last_updated(formatString: "YYYY-MM-DD")
          }
          body
        }
      }
    }
    cprimaries: allMdx(filter: { fileAbsolutePath: { regex: "/_en_compute_primary/" } }) {
      nodes {
        fileAbsolutePath
        frontmatter {
          cat_id
          section_name
          svg
          description
        }
      }
    }
    cprimary_orphans: allMdx(
      filter: {
        frontmatter: {
          # orphan: { eq: true },
          unlisted: { ne: true }
        }
        fileAbsolutePath: { regex: "/_en_compute//" }
      }
      sort: { fields: fileAbsolutePath }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        nodes {
          fileAbsolutePath
          slug
          frontmatter {
            primary_nav
            # secondary_nav
            header
            unlisted
            # orphan
          }
          body
        }
      }
    }

    fullsite_in_primarys: allMdx(
      filter: { slug: { regex: "/^_en_full_site_delivery//" }, frontmatter: { unlisted: { ne: true }, orphan: { ne: true } } }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        totalCount
        nodes {
          slug
          frontmatter {
            primary_nav
            secondary_nav
            header
            unlisted
            last_updated(formatString: "YYYY-MM-DD")
          }
          body
        }
      }
    }
    dprimaries: allMdx(filter: { fileAbsolutePath: { regex: "/_en_full_site_delivery_primary/" } }) {
      nodes {
        fileAbsolutePath
        frontmatter {
          cat_id
          section_name
          svg
          description
        }
      }
    }
    dprimary_orphans: allMdx(
      filter: {
        frontmatter: {
          # orphan: { eq: true },
          unlisted: { ne: true }
        }
        fileAbsolutePath: { regex: "/_en_full_site_delivery//" }
      }
      sort: { fields: fileAbsolutePath }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        nodes {
          fileAbsolutePath
          slug
          frontmatter {
            primary_nav
            # secondary_nav
            header
            unlisted
            # orphan
          }
          body
        }
      }
    }

    gettingstarted_in_primarys: allMdx(
      filter: { slug: { regex: "/^_en_getting_started//" }, frontmatter: { unlisted: { ne: true }, orphan: { ne: true } } }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        totalCount
        nodes {
          slug
          frontmatter {
            primary_nav
            secondary_nav
            header
            unlisted
            last_updated(formatString: "YYYY-MM-DD")
          }
          body
        }
      }
    }
    gprimaries: allMdx(filter: { fileAbsolutePath: { regex: "/_en_getting_started_primary/" } }) {
      nodes {
        fileAbsolutePath
        frontmatter {
          cat_id
          section_name
          svg
          description
        }
      }
    }
    gprimary_orphans: allMdx(
      filter: {
        frontmatter: {
          # orphan: { eq: true },
          unlisted: { ne: true }
        }
        fileAbsolutePath: { regex: "/_en_getting_started//" }
      }
      sort: { fields: fileAbsolutePath }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        nodes {
          fileAbsolutePath
          slug
          frontmatter {
            primary_nav
            # secondary_nav
            header
            unlisted
            # orphan
          }
          body
        }
      }
    }

    integrations_in_primarys: allMdx(
      filter: { slug: { regex: "/^_en_integrations//" }, frontmatter: { unlisted: { ne: true }, orphan: { ne: true } } }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        totalCount
        nodes {
          slug
          frontmatter {
            primary_nav
            secondary_nav
            header
            unlisted
            last_updated(formatString: "YYYY-MM-DD")
          }
          body
        }
      }
    }
    iprimaries: allMdx(filter: { fileAbsolutePath: { regex: "/_en_integrations_primary/" } }) {
      nodes {
        fileAbsolutePath
        frontmatter {
          cat_id
          section_name
          svg
          description
        }
      }
    }
    iprimary_orphans: allMdx(
      filter: {
        frontmatter: {
          # orphan: { eq: true },
          unlisted: { ne: true }
        }
        fileAbsolutePath: { regex: "/_en_integrations//" }
      }
      sort: { fields: fileAbsolutePath }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        nodes {
          fileAbsolutePath
          slug
          frontmatter {
            primary_nav
            # secondary_nav
            header
            unlisted
            # orphan
          }
          body
        }
      }
    }

    security_in_primarys: allMdx(
      filter: { slug: { regex: "/^_en_security//" }, frontmatter: { unlisted: { ne: true }, orphan: { ne: true } } }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        totalCount
        nodes {
          slug
          frontmatter {
            primary_nav
            secondary_nav
            header
            unlisted
            last_updated(formatString: "YYYY-MM-DD")
          }
          body
        }
      }
    }
    sprimaries: allMdx(filter: { fileAbsolutePath: { regex: "/_en_security_primary/" } }) {
      nodes {
        fileAbsolutePath
        frontmatter {
          cat_id
          section_name
          svg
          description
        }
      }
    }
    sprimary_orphans: allMdx(
      filter: {
        frontmatter: {
          # orphan: { eq: true },
          unlisted: { ne: true }
        }
        fileAbsolutePath: { regex: "/_en_security//" }
      }
      sort: { fields: fileAbsolutePath }
    ) {
      group(field: frontmatter___primary_nav) {
        field
        fieldValue
        nodes {
          fileAbsolutePath
          slug
          frontmatter {
            primary_nav
            # secondary_nav
            header
            unlisted
            # orphan
          }
          body
        }
      }
    }
  }
`
