import React from "react"

export const PageHeaderTable = (props) => {
  const { guide, section_url_prefix } = props
  const href_to_guide = `${section_url_prefix}${guide.slug.split("/", 2)[1]}`

  return (
    <table border="1" cellPadding={2} cellSpacing="0">
      <tr>
        <td>📄</td>
        <td style={{ width: "100%" }}>
          <h1 style={{ margin: "0" }}>{guide.frontmatter?.header}</h1>
        </td>
      </tr>
      {guide.frontmatter?.last_updated ? (
        <tr>
          <td>🗓️</td>
          <td>Last updated: {guide.frontmatter.last_updated}</td>
        </tr>
      ) : (
        <></>
      )}
      <tr>
        <td>🔗</td>
        <td>
          <a href={href_to_guide}>{href_to_guide}</a>
        </td>
      </tr>
    </table>
  )
}

// What is a Dinkus? https://en.wikipedia.org/wiki/Dinkus
export const DinkusBar = () => {
  return (
    <div style={{ textAlign: "center", fontFamily: "monospace" }}>
      <hr style={{ width: "40%", display: "inline-block" }} />
      {" * * * "}
      <hr style={{ width: "40%", display: "inline-block" }} />
    </div>
  )
}
